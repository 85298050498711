
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiBase, ApiExceptionHandlings } from "@/core/api";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import {
  commonBackToList,
  commonChangeDefaultDate,
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { TaggingItem } from "@/core/directive/interface/common";
import { getExceptionHandlingProcessMap } from "@/core/directive/function/exceptionHandling";

export default defineComponent({
  name: "risk-and-exception-exception-handling-information",
  components: {},
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);

    const formData = ref({
      id: route.params.id,
      type: "",
      fulfillment_order_number: "",
      title: "",
      customer_service_name: "",
      created_at: "",
      progress: "",
      progress_label: "",
      customer_request: "",
      solution: "",
      sale_order_number: "",
      category: "",
      cargo_damage_level: "",
      solution_remark: "",
      closed_date: "",
      level: 0,
      __show: {
        progress: "",
      },
    });

    const ExceptionHandling = ref({
      id: route.params.id,
      type: "",
      fulfillment_order_number: "",
      title: "",
      customer_service_name: "",
      created_at: "",
      progress: "",
      progress_label: "",
      customer_request: "",
      solution: "",
      sale_order_number: "",
      category: "",
      cargo_damage_level: "",
      solution_remark: "",
      closed_date: "",
      level: 0,
      __show: {
        progress: "",
      },
    });

    const options = ref({
      type: [] as TaggingItem[],
      progress: [] as TaggingItem[],
      solution: [] as TaggingItem[],
      customer: [] as TaggingItem[],
      category: [] as TaggingItem[],
      cargo_damage_level: [] as TaggingItem[],
    });

    const validatePass2 = (rule: any, value: any, callback: any) => {
      //  && formRef.value?.model.category == 2
      if (value === "") {
        callback(new Error(t("common.isRequired")));
      } else {
        callback();
      }
    };

    const rules = ref({
      type: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      fulfillment_order_number: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      title: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      // customer_service_id: [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      created_at: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      progress: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      // customer_request: [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      solution: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      category: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      cargo_damage_level: [
        {
          validator: validatePass2,
          trigger: "change",
        },
      ],
      // solution_remark: [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      // closed_date: [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
    });

    const handleCategory = () => {
      formData.value.cargo_damage_level = "";
    };

    const getCargoDamageLevel = computed(() => {
      let cargoDamageLevel: TaggingItem[] = [];
      if (formData.value.category !== "") {
        options.value.cargo_damage_level.map((item: TaggingItem) => {
          if (formData.value.category === "1" && item.value === "10") {
            cargoDamageLevel.push(item);
          } else if (formData.value.category === "2" && item.value === "20") {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "3" &&
            (item.value === "40" || item.value === "30")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "4" &&
            (item.value === "50" || item.value === "60" || item.value === "70")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "5" &&
            (item.value === "80" || item.value === "90" || item.value === "100")
          ) {
            cargoDamageLevel.push(item);
          } else if (formData.value.category === "6" && item.value === "110") {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "7" &&
            (item.value === "120" || item.value === "130")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "8" &&
            (item.value === "140" ||
              item.value === "150" ||
              item.value === "160")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "9" &&
            (item.value === "170" ||
              item.value === "180" ||
              item.value === "190" ||
              item.value === "200" ||
              item.value === "210")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "10" &&
            (item.value === "220" || item.value === "230")
          ) {
            cargoDamageLevel.push(item);
          }
        });
      }
      return cargoDamageLevel;
    });

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "exception_handling_type",
          "exception_handling_progress",
          "exception_handling_solution",
          "monitoring_rules_category",
          "monitoring_rules_cargo_damage_level",
        ],
      });
      if (data.code == 0) {
        options.value.type = data.data.exception_handling_type.items;
        options.value.progress = data.data.exception_handling_progress.items;
        options.value.solution = data.data.exception_handling_solution.items;
        options.value.category = data.data.monitoring_rules_category.items;
        options.value.cargo_damage_level =
          data.data.monitoring_rules_cargo_damage_level.items;
      }
    };

    const getShowInfo = async () => {
      const { data } = await ApiExceptionHandlings.showExceptions({
        id: route.params.id,
      });
      if (data.code == 0) {
        formData.value = data.data;
        Object.keys(data.data).forEach((item) => {
          ExceptionHandling.value[item] = data.data[item];
        });
        // getCustomerOptions("", data.data.customer_service_id);
      }
    };

    const getFromInfo = () => {
      loading.value = true;
      Promise.all([getShowInfo(), getTaggingData()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiExceptionHandlings.updateExceptions(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  getFromInfo();
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const getCustomerOptions = (query?: string, id?: string) => {
      let d = id ? { id: id } : { search_value: query };

      ApiBase.getUserSourceData(d)
        .then(({ data }) => {
          if (data.code == 0) {
            options.value.customer = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const statusClass = (status) => {
      return getExceptionHandlingProcessMap(Number(status), t);
    };

    const backToList = () => {
      commonBackToList(router, "/risk-and-exception/exception-handling");
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      getFromInfo();
    });

    return {
      t,
      formatDate,
      formatDateTime,
      commonChangeDefaultDate,
      loading,
      options,
      formData,
      ExceptionHandling,
      formRef,
      rules,
      submitButton,
      handleCategory,
      getCargoDamageLevel,
      getFromInfo,
      submit,
      getCustomerOptions,
      statusClass,
      backToList,
    };
  },
});
